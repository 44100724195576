var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-combobox",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          attrs: {
            items: _vm.availableItems,
            value: _vm.value,
            rules: _vm.computedRules,
            "item-text": "label",
            "item-value": "value"
          },
          on: { input: _vm.onInput }
        },
        "v-combobox",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }