<template>
    <v-combobox
        ref="input"
        :items="availableItems"
        :value="value"
        @input="onInput"
        v-on="listeners"
        v-bind="attrs"
        :rules="computedRules"
        item-text="label"
        item-value="value"
    >
    </v-combobox>
</template>

<script>
import GroupByConfig from '@/models/GroupByConfig'

export default {
    name: 'ReportGroupByCombobox',
    inheritAttrs: false,
    props: {
        value: {
            type     : [Array, GroupByConfig],
            validator: a => a instanceof GroupByConfig || a.every(i => i instanceof GroupByConfig),
        },
        availableItems: {
            type     : Array,
            validator: a => a.every(i => i instanceof GroupByConfig),
        },
        max: {
            type: Number,
            default: () => 3,
        },
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },

        itemsCount() {
            return Array.isArray(this.value) ? this.value.length : 1
        },
        computedRules() {
            let rules = this.$attrs.rules || []
            rules.push(
                (v) => this.itemsCount > this.max ? this.$t('components.inputs.ReportGroupByCombobox.invalid_max', { max: this.max }) : true
            )
            return rules
        },
    },
}
</script>