
import axios from '@/services/axios' 
import BaseModel from '@/models/BaseModel'

export default class GroupByConfig extends BaseModel {

    defaults() {
        return {
            value: '',
            label: '',
        }
    }

}