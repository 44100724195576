<template>
    <v-container class="pa-0">
        <v-alert type="info" dense class="my-4">{{ $t('views.reports.layout_info') }}</v-alert>
        <ReportHeader
            :title="$t('views.reports.interactions.title')"
            :description="$t('views.reports.interactions.description')"
            icon="fas fa-user"
        >
            <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
                <v-row>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.from.label')"
                            :placeholder="$t('views.reports.commons.form.from.placeholder')"
                            :hint="$t('views.reports.commons.form.from.hint')"
                            v-model="params.from"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.to.label')"
                            :placeholder="$t('views.reports.commons.form.to.placeholder')"
                            :hint="$t('views.reports.commons.form.to.hint')"
                            v-model="params.to"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ReportGroupByCombobox
                            v-model="computedGroupBy"
                            :label="$t('views.reports.commons.form.group_by.label')"
                            :hint="$t('views.reports.commons.form.group_by.hint')"
                            :placeholder="$t('views.reports.commons.form.group_by.placeholder')"
                            :availableItems="groupByAvailableItems"
                            multiple small-chips
                            clearable hide-selected
                            deletable-chips persistent-hint
                            :max="3"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            :label="$t('views.reports.commons.form.count.label')"
                            v-model="computedCount"
                        />
                    </v-col>
                    <v-col>
                        <v-checkbox
                            :label="$t('views.reports.commons.form.include_reversals.label')"
                            v-model="computedIncludeReversals"
                        />
                    </v-col>
                </v-row>

                <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="!isFormValid"
                >{{ $t('actions.confirm') }}</v-btn>
            </v-form>
        </ReportHeader>

        <ReportContent
            :headers="computedHeaders"
            :reportResponse="reportResponse"
            :loading="loading"
            :pagination.sync="pagination"
            :hasError="hasError"
            :count="computedLastCount"
            :model="CustomerHistoryRecord"
            :i18n-mapping="i18nMapping"
            @export="exportReport"
        >
            <!-- <template #group-item="{ item, tdClass }">
                <td :class="tdClass">{{ item.operations['sum:value'] | currency }}</td>
                <td :class="tdClass">{{ item.operations['sum:amount'] | dynamicPoint }}</td>
                <td :class="tdClass">{{ item.operations['sum:spent_value'] | currency }}</td>
            </template> -->
            <template #item="{ item }">
                <tr v-if="!computedLastCount">
                    <td>{{ item.created_at | datetime }}</td>
                    <td>{{ item.customer_id }}</td>
                    <td>{{ item.company_name }}</td>
                    <td>{{ item.getChrTypeLabel() }}</td>
                    <td>{{ item.value | currency }}</td>
                    <td>{{ item.amount | dynamicPoint }}</td>
                    <!-- <td>{{ item.reversal_id }}</td> -->
                </tr>
            </template>
        </ReportContent>

    </v-container>
</template>
<script>
import HasErrorHandlerMixin            from '@/mixins/HasErrorHandlerMixin'
import RateColor                       from '@/shared/mixins/RateColor'
import DateInput                       from '@/shared/components/inputs/DateInput'
import ReportHeader                    from '../commons/ReportHeader'
import ReportContent                   from '../commons/ReportContent'
import CustomerHistoryRecordRepository from '@/repositories/CustomerHistoryRecordRepository'
import ReportResponse                  from '@/models/ReportResponse'
import GroupByConfig                   from '@/models/GroupByConfig'
import CustomerHistoryRecord           from '@/models/CustomerHistoryRecord'
import ReportGroupByCombobox           from '@/components/inputs/ReportGroupByCombobox'

// const REPORT_OPERATIONS = ''

export default {
    mixins    : [ HasErrorHandlerMixin, RateColor ],
    components: { DateInput, ReportHeader, ReportContent, ReportGroupByCombobox },
    data      : vm => ({
        CustomerHistoryRecord,
        params: {
            from             : vm.$route.query.from                 || '',
            to               : vm.$route.query.to                   || '',
            group_by         : vm.$route.query.group_by             || '',
            count            : vm.$route.query.count                || 'true',    // Por padrão mostra apenas as contagens
            include_reversals: vm.$route.query.include_reversals    || 'false',
        },
        lastParams: {
            from             : undefined,
            to               : undefined,
            group_by         : undefined,
            count            : undefined,
            include_reversals: undefined,
        },
        i18nMapping: {
            'field:chr_type': CustomerHistoryRecord.getChrTypeLabel,
        },

        groupByAvailableItems: [
            new GroupByConfig({ value: `field:company.id`,  label: vm.$t('views.reports.interactions.form.group_by.company') }),
            new GroupByConfig({ value: `field:customer_id`, label: vm.$t('views.reports.interactions.form.group_by.customer') }),
            new GroupByConfig({ value: `field:chr_type`,    label: vm.$t('views.reports.interactions.form.group_by.chr_type') }),

            new GroupByConfig({ value: `time:created_at:1:HOUR`,  label: vm.$t('views.reports.commons.form.group_by.hour') }),
            new GroupByConfig({ value: `time:created_at:1:DAY`,   label: vm.$t('views.reports.commons.form.group_by.day') }),
            new GroupByConfig({ value: `time:created_at:1:WEEK`,  label: vm.$t('views.reports.commons.form.group_by.week') }),
            new GroupByConfig({ value: `time:created_at:1:MONTH`, label: vm.$t('views.reports.commons.form.group_by.month') }),
            new GroupByConfig({ value: `time:created_at:1:YEAR`,  label: vm.$t('views.reports.commons.form.group_by.year') }),
        ],

        loading       : false,
        hasError      : false,
        isFormValid   : true,
        reportResponse: new ReportResponse,
        pagination    : {
            page    : vm.$route.query.page || 1,
            per_page: vm.$constants.getConstant('REPORT_DEFAULT_PER_PAGE'),
        },
    }),
    methods: {
        async submit() {
            this.pagination.page = 1
            await this.fetchData()
        },
        async fetchData() {
            this.loading      = true
            this.hasError     = false
            this.dataResponse = {}

            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading  = false
                return
            }

            // Atualiza parâmetros da busca na query para poder voltar com o navegador
            this.$router.push({ query: this.computedQuery })

            // Logo em seguida faz request para listar
            this.reportResponse = await CustomerHistoryRecordRepository.fetchReport(this.computedPagination, this.computedParams)
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        async exportReport() {
            // [TEMP][FID-463] Implementação provisória, será melhorada na FID-463
            this.loading  = true
            this.hasError = false

            // Valida parâmetros atuais
            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading = false
                return
            }

            // Logo em seguida faz request para exportar
            await CustomerHistoryRecordRepository.fetchReport(this.computedPagination, { ...this.params, export: true, count: false })
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        preErrorHandler(e) {
            this.hasError   = true
            this.errorHandler(e)
        },
    },
    computed: {
        computedQuery() {
            return {
                ...this.computedPagination,
                ...this.params,
            }
        },
        computedParams() {
            // Sempre coloca field:root antes do group_by
            // let rootGroupBy = `field:root,${REPORT_OPERATIONS}`
            // let modifiedParams = { ...this.params }

            // modifiedParams.group_by = `${rootGroupBy};${modifiedParams.group_by}`

            // return modifiedParams
            return { ...this.params }
        },
        computedPagination() {
            if (this.computedCount) {
                return this.$lodash.omit(this.pagination, ['per_page', 'page'])
            }

            return this.pagination
        },
        computedGroupBy: {
            get() {
                return (this.params.group_by || '')
                    .split(';')
                    .map(i => this.groupByAvailableItems.find(a => a.value == i))
                    .filter(i => !!i)
            },
            set(v) {
                // Sempre mostra a soma total no root
                this.params.group_by = v.map(i => i.value).join(';')
            },
        },
        computedCount: {
            get() {
                return (this.params.count || '').includes('true')
            },
            set(v) {
                this.params.count = v ? 'true' : 'false'
            },
        },
        computedIncludeReversals: {
            get() {
                return (this.params.include_reversals || '').includes('true')
            },
            set(v) {
                this.params.include_reversals = v ? 'true' : 'false'
            },
        },
        computedLastCount() {
            return (this.lastParams.count || '').includes('true')
        },
        computedHeaders() {
            return this.computedLastCount ? 
            [
                { text: this.$t('views.reports.commons.header.label') },
                { text: this.$t('views.reports.commons.header.count'), align: 'right' },
                // { text: this.$t('views.reports.commons.content.model_count') },
            ] : [
                { text: this.$t('views.reports.interactions.content.created_at'),        value: 'created_at' },
                { text: this.$t('views.reports.interactions.content.customer_document'), value: 'customer_document' },
                { text: this.$t('views.reports.interactions.content.company_name'),      value: 'company_name' },
                { text: this.$t('views.reports.interactions.content.chr_type'),          value: 'chr_type' },
                { text: this.$t('views.reports.interactions.content.value'),             value: 'value' },
                { text: this.$t('views.reports.interactions.content.amount'),            value: 'amount' },
                // { text: this.$t('views.reports.interactions.content.reversal_id'),       value: 'reversal_id' },
            ]
        },
    },
    watch: {
        pagination: {
            deep: true,
            immediate: true,
            handler(newPagination) {
                this.fetchData()
            },
        },
    },
}
</script>

<style scoped>
.show_customer {
    color: #304FFE !important;
}
</style>